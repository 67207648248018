@use "../../../styles/mixins" as *;
@include layer(ui, field) {
    .wrapper {
        font-family: var(--g-font-family-primary);
        color: var(--s-color-text-primary);
        display: flex;
        flex-direction: column;
    }

    .label {
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-300);
        line-height: var(--g-line-height-300);
        margin-bottom: var(--g-spacing-xs);
    }

    .marker {
        margin-left: var(--g-spacing-sm);
        color: var(--s-color-text-quiet);
    }

    .description {
        color: var(--s-color-text-secondary);
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        margin-top: var(--g-spacing-sm);
        font-size: var(--g-font-size-200);
        line-height: var(--g-line-height-200);
        display: grid;
        grid-template-columns: auto;
        row-gap: var(--g-spacing-xs);
        @include for-tablet-up {
            grid-template-columns: auto auto;
        }
        justify-content: space-between;
    }

    .error {
        display: flex;
        align-items: center;
        margin: 0;
        &__icon {
            width: var(--g-size-xxs);
            height: var(--g-size-xxs);
            margin-right: var(--g-spacing-sm);
        }
    }

    .invalid {
        &,
        .description {
            color: var(--s-color-alert-standard);
        }
    }
    .disabled {
        &,
        .marker,
        .description,
        .error {
            color: var(--s-color-disabled-primary);
        }
    }
}
